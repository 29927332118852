import React from "react"
import { graphql } from "gatsby"

import CaseStudyListContent from "./case-study-list-content"

const CaseStudyListing = props => <CaseStudyListContent {...props} />

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $market: String!) {
    sanityCsListing(tabs: { content: { market: { eq: $market } } }) {
      tabs {
        content {
          hero
          subtitle
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityCs(filter: { tabs: { content: { market: { eq: $market } } } }, limit: $limit, skip: $skip) {
      nodes {
        tabs {
          content {
            title
            category {
              slug {
                current
              }
            }
            slug {
              current
            }
            hero {
              _rawRichText
              _rawBackgroundImage
            }
            market
          }
          csListingPreview {
            logo {
              asset {
                url
                _id
              }
            }
            excerpt
            label {
              title
              color
            }
          }
        }
        _type
      }
    }
    allSanityCsCategory(filter: { market: { eq: $market } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`

export default CaseStudyListing
